<template>
	<div class="row mx-1">
		<!-- Column -->
		<e-select
			class="col-3 p-0"
			v-model="internalColumn"
			:options="availableColumns"
			:searchable="true"
			:allow-empty="false"
			:show-labels="true"
			track-by="key"
			label="label"
		/>
		<!-- Operator -->
		<e-select
			class="col-3 p-0"
			v-model="internalOperator"
			:options="availableOperators"
			:searchable="true"
			:sortable="false"
			:allow-empty="false"
			:show-labels="true"
			track-by="key"
			label="label"
		/>
		<!-- Input -->
		<b-form-input
			class="col-5"
			ref="searchfield"
			v-model="internalValue"
			type="text"
			:placeholder="getTrad('global.recherchez_tableau')"
			debounce="500"
		/>
		<!-- Bouton vider le champs -->
		<b-button
			class="col-1 p-0"
			:disabled="!internalValue"
			@click="internalValue = ''"
		>
			<font-awesome-icon :icon="['fal', 'trash-alt']"/>
		</b-button>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'TableSearchInput',
		props: {
			searchColumn: String,
			searchOperator: String,
			searchValue: String,
			columns: Array
		},
		data: () => ({
			availableOperators: [],
			allowedOperators: [
				'isEqualTo',
				'isNotEqualTo',
				'isLowerThan',
				'isLowerOrEqualThan',
				'isGreaterThan',
				'isGreaterOrEqualThan',
				'mustContain',
				'mustNotContain',
				'beginWith',
				'doesntBeginWith',
				'endWith',
				'doesntEndWith',
				'isEmpty',
				'isNotEmpty',
				'isTrue',
				'isFalse'
			]
		}),
		computed: {
			internalColumn: {
				get() {
					return this.availableColumns.find(col => (col.key === this.searchColumn))
				},
				set(val) {
					this.$emit('update:searchColumn', val.key)
				}
			},
			internalOperator: {
				get() {
					return this.availableOperators.find(op => (op.key === this.searchOperator))
				},
				set(val) {
					this.$emit('update:searchOperator', val.key)
				}
			},
			internalValue: {
				get() {
					return this.searchValue
				},
				set(val) {
					this.$emit('update:searchValue', val)
				}
			},
			availableColumns() {
				return [{
					key: 'stringified',
					label: this.$t('table.general.search_stringified')
				}]
				.concat(this.columns)
			}
		},
		created() {
			const operators = this.getConfig('action_filter')

			this.availableOperators = operators
			.filter(op => {
				return this.allowedOperators.includes(op.action)
			})
			.map(op => {
				return {
					key: op.action,
					label: this.$t(`filtres.${op.label}`)
				}
			})
		},
		methods: {
			focus() {
				this.$refs.searchfield.$el.focus()
			}
		}
	}
</script>
